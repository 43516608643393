// src/components/IngredientsInput.js
import React from 'react';

const IngredientsInput = ({ ingredients, setIngredients }) => {
  const handleIngredientChange = (index, value) => {
    const newIngredients = [...ingredients];
    newIngredients[index] = value;
    setIngredients(newIngredients);
  };

  const addIngredient = () => {
    setIngredients([...ingredients, '']);
  };

  return (
    <div className="form-group">
      <label>Ingredients:</label>
      {ingredients.map((ingredient, index) => (
        <div key={index} className="form-group">
          <input
            type="text"
            value={ingredient}
            onChange={(e) => handleIngredientChange(index, e.target.value)}
          />
        </div>
      ))}
      <button type="button" className="add-button" onClick={addIngredient}>
        Add Ingredient
      </button>
    </div>
  );
};

export default IngredientsInput;