import React from 'react';
import TitleInput from './TitleInput';
import IngredientsInput from './IngredientsInput';
import DirectionsInput from './DirectionsInput';

const RecipeDetails = ({ title, setTitle, ingredients, setIngredients, directions, setDirections }) => {
  return (
    <div>
      <TitleInput title={title} setTitle={setTitle} />
      <IngredientsInput ingredients={ingredients} setIngredients={setIngredients} />
      <DirectionsInput directions={directions} setDirections={setDirections} />
    </div>
  );
};

export default RecipeDetails;