import React from 'react';

const DirectionsInput = ({ directions, setDirections }) => {
  const handleDirectionChange = (index, value) => {
    const newDirections = [...directions];
    newDirections[index] = value;
    setDirections(newDirections);
  };

  const addDirection = () => {
    setDirections([...directions, '']);
  };

  return (
    <div className="form-group">
      <label>Directions:</label>
      {directions.map((direction, index) => (
        <div key={index} className="form-group">
          <input
            type="text"
            value={direction}
            onChange={(e) => handleDirectionChange(index, e.target.value)}
          />
        </div>
      ))}
      <button type="button" className="add-button" onClick={addDirection}>
        Add Direction
      </button>
    </div>
  );
};

export default DirectionsInput;