import React, { useEffect } from 'react';
import RecipeDetails from './RecipeDetails';
import CollapsibleSection from './CollapsibleSection';

const RecipeForm = ({
  title,
  setTitle,
  ingredients,
  setIngredients,
  directions,
  setDirections,
  handlePrint,
}) => {
  useEffect(() => {
    const fetchRecipe = async () => {
      try {
        const response = await fetch('http://localhost:8000/lemon_vinaigrette_chicken_panini_with_chipotle_mayo.json');
        const data = await response.json();
        setTitle(data.title);
        setIngredients(data.ingredients);
        setDirections(data.directions);
      } catch (error) {
        console.error('Error fetching the recipe:', error);
      }
    };

    fetchRecipe();
  }, [setTitle, setIngredients, setDirections]);

  return (
    <div className="input-panel">
      <h2>Recipe Printer</h2>
      <div className="collapsible-sections">
        <CollapsibleSection title="Formatting">
          {/* Add formatting options here */}
          <div>Formatting options will go here</div>
        </CollapsibleSection>
        <CollapsibleSection title="Recipe Details">
          <RecipeDetails
            title={title}
            setTitle={setTitle}
            ingredients={ingredients}
            setIngredients={setIngredients}
            directions={directions}
            setDirections={setDirections}
          />
        </CollapsibleSection>
      </div>
      <button className="print-button" onClick={handlePrint}>
        Print Recipe
      </button>
    </div>
  );
};

export default RecipeForm;