// src/App.js
import React, { useState } from 'react';
import './App.css';
import RecipeDisplay from './components/RecipeDisplay';
import RecipeForm from './components/RecipeForm';

function App() {
  const [title, setTitle] = useState('');
  const [ingredients, setIngredients] = useState(['']);
  const [directions, setDirections] = useState(['']);

  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="app-container">
      <div className="paper">
        <RecipeDisplay
          title={title}
          ingredients={ingredients}
          directions={directions}
        />
      </div>
      <RecipeForm
        title={title}
        setTitle={setTitle}
        ingredients={ingredients}
        setIngredients={setIngredients}
        directions={directions}
        setDirections={setDirections}
        handlePrint={handlePrint}
      />
    </div>
  );
}

export default App;