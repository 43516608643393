// src/components/RecipeDisplay.js
import React from 'react';

const RecipeDisplay = ({ title, ingredients, directions }) => {
  return (
    <div className="recipe-display">
      <h1>{title}</h1>
      
      <h2>Ingredients:</h2>
      <ul className="ingredient">
        {ingredients.map((ingredient, index) => (
          <li key={index}>{ingredient}</li>
        ))}
      </ul>

      <h2>Instructions:</h2>
      <ol className="step">
        {directions.map((direction, index) => (
          <li key={index}>{direction}</li>
        ))}
      </ol>
    </div>
  );
};

export default RecipeDisplay;